.scene {
    width: 100vw;
    height: 50vh;
    perspective: 500px;
    align-content: center;
    text-align: center;
    transform: translateX(-2em);
}
.card {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.face {
    width: 100%;
    height: 100%;
    color: #fff;
    line-height: 15em;
    text-align: center;
    position: absolute;

    backface-visibility: hidden;
}

.back {
    transform: rotateY(180deg);
}
.scene:hover .card {
    transform: rotateY(180deg);
}

.image-fid {
    max-height: 50vh;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .image-fid {
        max-height: 30vh;
    }
    .scene {
        width: 100vw;
        height: 30vh;
        perspective: 500px;
        align-content: center;
        text-align: center;
        transform: translateX(-2em);
    }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1000px) and (-webkit-min-device-pixel-ratio: 2) {
    .image-fid {
        max-height: 30vh;
    }
    .scene {
        width: 100vw;
        height: 30vh;
        perspective: 500px;
        align-content: center;
        text-align: center;
        transform: translateX(-2em);
    }
}
